.contact-page {
  height: 100%;
  width: 100%;
}

.contact-page.page-content {
  /* height: calc(100% - 50px); */
  /* height: calc(100vh - 100px); */
  /* min-height: calc(100vh - 100px); */
  height: auto;
  width: 100%;
}

.icon-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  height: auto;
  width: auto;
  margin-top: 100px;
}

.icon-box {
  margin: auto 30px;
  position: relative;
}

.icon {
  position: relative;
}

.icon path {
  stroke-width: 2;
}

.icon g.subtitle-text path {
  stroke-width: 0;
  fill: rgba(245, 245, 245, 0.65);
  opacity: 0;
  transition: opacity 1s ease;
  animation: none;
}

.icon:hover g.subtitle-text path {
  opacity: 1;
}

.contact-icon .icon {
  position: absolute;
  transform: translateY(calc(-100% - 4px));
}

@media only screen and (max-width: 768px) {
  .icon-bar {
    margin-top: 20px;
  }

  /* .icon g.subtitle-text path {
    opacity: 1;
  } */
}
