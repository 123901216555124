* {
  box-sizing: border-box;
  max-width: 100vw;
}

header {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  padding-top: 10px;
}

main {
  /* min-height: calc(100vh - 50px); */
  /* height: calc(100vh - 50px); */
  /* --height-var: calc(100vh - 50px);
  height: max(var(--height-var), 100%); */
  /* height: 100%; */
  height: auto;
  width: 100%;
}

.App {
  min-height: 100vh;
  /* min-width: 950px; */
  background: blue;
  background-size: 20px 20px;
  background-image: linear-gradient(
      to right,
      rgba(245, 245, 245, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(245, 245, 245, 0.2) 2px, transparent 2px);
}

.page-header {
  width: 100%;
  height: 50px;
}

.page-title {
  height: 33px;
  width: auto;
  margin-left: 5%;
  margin-top: 18px;
}

.page-title path.texture {
  fill-opacity: 0.5;
}

.page-title path {
  stroke-width: 1;
}

.transparent svg * {
  fill: transparent;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes delay-appear {
  0%,
  100% {
    opacity: 0;
  }
}

@keyframes reset-stroke-width {
  to {
    stroke-width: 0;
  }
}

@media only screen and (max-width: 768px) {
  header {
    flex-direction: column;
    height: auto;
  }

  .page-header {
    display: none;
  }
}
