nav.pages-nav-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 12px;
  justify-content: space-between;
  width: 500px;
}

.clickable {
  cursor: pointer;
}

.header-style {
  height: 30px;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
}

path.texture {
  transition: fill-opacity 0.2s ease;
}

svg.highlight:hover path.texture {
  fill-opacity: 0.5;
}

.header-style.projects {
  height: 37px;
}

@media only screen and (max-width: 768px) {
  nav.pages-nav-bar {
    margin: auto;
    justify-content: space-around;
    width: 100%;
  }
  .header-style {
    margin: 0px;
  }
}
