.home-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
  min-height: 300px;
}

.banner-text {
  height: auto;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin: auto 0px;
  padding-left: 30px;
  position: relative;
}

.banner-text > * {
  margin-right: 0px;
  margin-left: auto;
  height: auto;
}

.banner {
  margin: auto 0px auto auto;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .home-page {
    flex-direction: column-reverse;
    height: calc(100vh - 120px);
  }
  .banner-text {
    width: 100%;
    margin: 30px auto auto auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  .banner-text > * {
    margin: auto;
    margin-bottom: auto;
  }
}
