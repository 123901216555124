.skills-logos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: baseline;
  height: auto;
  padding: 20px 0px;
}

.logo-box {
  width: auto;
  height: auto;
  margin: 10px;
  position: relative;
}

.logo-outline {
  position: relative;
}

.logo-icon {
  /* only making the filled svgs position absolute makes it so that we always have a reference for height in the flow */
  position: absolute;
  top: 0;
}

.logo {
  height: 100px;
  width: auto;
  transition: transform 0.5s ease;
}

.selected .logo {
  transform: scale(1.3) !important;
  cursor: default !important;
  z-index: 2;
}

.logo-icon .logo:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.logo:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.logo path {
  stroke-width: 2;
}

.logo-icon > svg {
  opacity: 0;
  animation: fade-in 1s ease 3s forwards;
}

.logo-icon > svg path {
  stroke-width: 1;
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 60px;
    width: auto;
  }

  .selected .logo {
    transform: scale(1.5) !important;
  }
}
