.skills-banner {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.skills-title {
  width: auto;
  margin: 30px auto 15px auto;
  height: 10%;
}

.skills-title path {
  stroke-width: 0;
}

@media only screen and (max-width: 768px) {
  .skills-title {
    margin: 0;
  }

  .skills-title svg {
    /* width: calc(100vw - 40px); */
    height: auto;
    padding: 0 20px;
    margin: auto 0;
  }
}
