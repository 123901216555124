.project-box {
  position: relative;
  z-index: 0;
}

.project-box-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  background-color: #b3b3b3;
  border: solid 1px whitesmoke;
  border-radius: 5px;

  position: relative;
  z-index: 1;
}

.project-image-container {
  width: 30%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.project-image {
  width: 100%;
  height: auto;
}

.project-description-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  padding: 5px;
  padding-bottom: 15px;
}

.project-title {
  margin: 10px 0px 15px 0px;
  padding-top: 5px;
  font-size: 25px;
}

.project-description {
  overflow-y: auto;
  margin: 0;
  white-space: pre-wrap;
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.project-links > * {
  margin: 10px;
}

.project-link-image {
  width: 32px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .project-links > * {
    margin: 5px;
  }

  .project-title {
    font-size: 20px;
  }
}
