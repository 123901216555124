section {
  margin: auto auto 10px 0px;
  padding-top: 10px;
}

.name svg {
  height: 40px;
  width: auto;
  margin-left: 40px;
}

.name svg path {
  transition: stroke-width 0.5s ease-in-out;
}

.lowstroke svg path {
  stroke-width: 0.5;
}

@media only screen and (max-width: 768px) {
  .name svg {
    margin-left: 20px;
  }
}
