.projects-page {
  width: 100%;
  height: auto;
}

.projects-page.page-content {
  min-height: calc(100vh - 100px);
  height: auto;
}

.projects-page .page-header .page-title {
  height: 41px;
}

.project-box-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
