.portrait-banner {
  width: 100%;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.portrait-description {
  width: 50%;
  height: auto;
  margin: auto;
}

.portrait-container {
  width: 50%;
  height: auto;
  position: relative;
  margin: 10px;
}

.selected-portrait {
  width: auto;
  height: 100%;
  max-height: 400px;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img.selected-portrait {
  z-index: 1;
  opacity: 0.98;
  clip-path: circle(46%);
  transition: opacity 0.5s ease;
}

img.selected-portrait:hover {
  opacity: 0;
}

div.portrait svg.selected-portrait path {
  stroke-width: 0;
}

.description-box {
  margin: 30px 10px;
  padding: 10px;
  background-color: rgba(245, 245, 245, 0);
  width: calc(100% - 60px);
  height: auto;
  min-height: 300px;
}

.description-box > .title-wrapper {
  text-align: center;
}

.title-wrapper > h1.title {
  margin: 0px;
  color: rgba(245, 245, 245, 1);
  text-decoration: underline;
}

.description-box > h3.description {
  white-space: pre-wrap;
  font-size: 1.2rem;
  color: rgba(245, 245, 245, 0.9);
}

@media only screen and (max-width: 768px) {
  .portrait-container {
    width: 100vw;
    height: 100vw;
    padding: 20px;
    margin: 0;
    max-height: 440px;
  }

  .portrait-container * {
    width: calc(100vw - 40px);
    height: calc(100vw - 20px);
    max-height: 400px;
    max-width: 400px;
    /* margin: 0px auto; */
  }

  img.selected-portrait {
    width: auto;
    -webkit-clip-path: circle(46%);
    clip-path: circle(46%);
  }

  .portrait-description,
  .description-box {
    width: calc(100vw - 20px);
  }

  .portrait-banner {
    flex-direction: column;
  }

  .description-box {
    margin: 0;
  }
}
@media only screen and (max-width: 500px) {
  img.selected-portrait {
    -webkit-clip-path: circle(44%);
    clip-path: circle(45%);
  }
}

@media only screen and (max-width: 449px) {
  img.selected-portrait {
    -webkit-clip-path: circle(44%);
    clip-path: circle(44%);
  }
}

@media only screen and (max-width: 400px) {
  img.selected-portrait {
    -webkit-clip-path: circle(43%);
    clip-path: circle(43%);
  }
}
