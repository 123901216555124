.figures {
  width: 45%;
  height: 100%;
  position: relative;
}

.figure-style {
  position: absolute;
  height: 90%;
  width: auto;
  max-width: 100%;
  padding: 10px 0px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Element Specific */

.light-bulb {
  width: 400px;
}

.ballerina-sketch {
  opacity: 0.75;
  z-index: 0;
}

.ballerina-filled {
  transform: translateX(-1%);
  z-index: 1;
}

/* Light Bulb element properties */
.light-bulb path {
  /* moving the svg up because I think it looks better */
  /* must move the path instead of the svg itself because otherwise the "svg's border-box" moves and blocks the header  */
  transform: translateY(-50px);
}

.off path {
  stroke-width: 2;
}

.off {
  z-index: 0;
}

.on {
  z-index: 1;
}

.off .sparks path {
  visibility: hidden;
  animation: sparks-appear 0.5s linear 8s;
}

.on .sparks path {
  visibility: hidden;
}

/* Ballerina specific */

@keyframes sparks-appear {
  from {
    visibility: visible;
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: -1;
    visibility: hidden;
  }
}

/* Ballerina Element Properties */

.ballerina-sketch path {
  stroke-width: 3 !important;
}

.ballerina-filled path {
  stroke-width: 0;
}

@media only screen and (max-width: 768px) {
  .figures {
    width: 100%;
    height: 60%;
  }
}
